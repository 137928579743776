<template>
  <validation-observer ref="webSettings">
    <b-row>
      <b-col md="3">
        <b-card>
          <validation-provider
            #default="{ errors }"
            name="Название"
            rules="required"
          >
            <b-form-group label="Название" label-for="title">
              <b-form-input
                id="title"
                v-model="settings.title"
                placeholder="Название"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <h4 class="mt-4 mb-1">Логотип:</h4>
          <div class="text-center">
            <b-img
              v-if="logo"
              class="mb-1"
              thumbnail
              fluid
              :src="logo"
              alt="Логотип"
            />
            <div
              v-if="!logo"
              class="mb-1"
              style="border: 1px dashed #ccc; padding: 74px; border-radius: 5px"
            >
              Нет Фото
            </div>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Логотип"
            rules="required"
          >
            <b-form-file
              v-model="settings.logo"
              accept="media/"
              :browse-text="$t('general.upload')"
              :placeholder="$t('general.select_file')"
              :drop-placeholder="$t('general.select_file')"
              @input="toBase('logo', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-1">Баннер:</h4>
          <div class="w-100 text-center">
            <b-img
              v-if="banner"
              class="mb-1 w-100"
              thumbnail
              fluid
              :src="banner"
              alt="Баннер"
            />
            <div
              v-if="!banner"
              class="mb-1"
              style="
                border: 1px dashed #ccc;
                padding: 300px;
                border-radius: 5px;
              "
            >
              Нет Фото
            </div>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Баннер"
            rules="required"
          >
            <b-form-file
              v-model="settings.banner"
              accept="media/"
              :browse-text="$t('general.upload')"
              :placeholder="$t('general.select_file')"
              :drop-placeholder="$t('general.select_file')"
              @input="toBase('banner', $event)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="save()"
        >
          Сохранить
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import formatter from '@/mixins/formmatter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [formatter],
  data() {
    return {
      required,
      banner: null,
      logo: null,
      settings: {
        title: '',
        logo: null,
        banner: null,
      },
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_ADMINISTRATION']),
  },
  mounted() {
    this.FETCH_ADMINISTRATION().then(() => {
      // eslint-disable-next-line prefer-destructuring
      const { title, logo, banner } = this.GET_ADMINISTRATION[0]
      this.settings = title
      this.logo = logo
      this.banner = banner
    })
  },
  methods: {
    ...mapActions('knowledgeBase', [
      'FETCH_ADMINISTRATION',
      'CREATE_ADMINISTRATION',
      'UPDATE_ADMINISTRATION',
    ]),
    toBase(data, event) {
      if (data === 'logo') {
        if (event) {
          this.$_convertToBase64(event).then(res => {
            this.logo = res
          })
        } else {
          this.logo = null
        }
      } else if (event) {
        this.$_convertToBase64(event).then(res => {
          this.banner = res
        })
      } else {
        this.banner = null
      }
    },
    save() {
      this.$refs.webSettings.validate().then(async success => {
        if (success) {
          const data = this.$_objectToFormData(this.settings)
          this.CREATE_ADMINISTRATION(data)
            .then(() => {
              this.$_successToast(this.$t('general.saved'))
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
  },
}
</script>

<style></style>
